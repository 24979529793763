import { useEventListener } from '@vueuse/core';

const addEventListener = () => {
    const closeCouponButton = document.getElementById('close-coupon');
    const offerPopup = document.getElementById('offer-popup');
    const offerBubble = document.getElementById('offer-bubble');

    function openOffer() {
        offerPopup.classList.add('active');
    }

    function closeOffer() {
        offerPopup.classList.remove('active');
    }

    useEventListener(offerBubble, 'click', openOffer);
    useEventListener(closeCouponButton, 'click', closeOffer);
};

useEventListener(document, 'DOMContentLoaded', addEventListener);
